//
// Profile card
//

.card-profile-image {
    position: relative;

    img {
        max-width: 180px;
        border-radius: $border-radius;
        @extend .shadow;
        transform: translate(-50%,-30%);
        position: absolute;
        left: 50%;
        transition: $transition-base;

        &:hover {
            transform: translate(-50%, -33%);
        }
    }
}
.cardbody, .cardbody2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cardbody2 {
    width: 60%;
    justify-content: space-between;
}
.col1, .col2 {
    width: 37.5%;
}
.col10, .col20 {
    width: 90%;
    // display: flex;
    // flex-direction: row;
}
.col1plus2 {
    width: 75%;
}
.col3 {
    width: 25%;
}
.expenses {
    h4 {
        padding-left: 25px;
    }
}

.cops {
    width: 50%;
    h4 {
        padding-left: 25px;
    }
}
.col1, .col2, .col3 {
    padding: 1em;
    text-transform: uppercase !important;
}

.smoke {
    background-color: #f1f1f1;
    margin-bottom: 2em;
}

.name__val {
    font-size: 25px;
}

.paisa {
    font-size: 50px;
}

.card-profile-stats {
    padding: 1rem 0;

    > div {
        text-align: center;
        margin-right: 1rem;
        padding: .875rem;

        &:last-child {
            margin-right: 0;
        }

        .heading {
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
        }
        .description {
            font-size: .875rem;
            color: $gray-500;
        }
    }
}

.card-profile-actions {
    padding: .875rem;
}
